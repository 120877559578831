

var activeTabId,
	breakpointMobile = 992,
	isMobile = null,
	wasMobile = null;


/**
 * Initialize
 */
function initAccordionTabs () {
	//console.log('initAccordionTabs()');

	$('.js-tab-button').on('click', function () {
		activeTabId = $(this).data('tab');
		//console.log('tabId:', tabId);

		toggleAccordionTabs(activeTabId);
		resizeWrapper(activeTabId);
		
	});

	activeTabId = 1;
	toggleAccordionTabs(activeTabId);
	resizeWrapper(activeTabId);

	$(window).smartresize(function() {
        //console.log('smartresize');
		resizeWrapper(activeTabId);
    });
}

/**
 * Toggle accordion tabs open/closed
 * @param {*} id 
 */
function toggleAccordionTabs(id) {
	//console.log('toggleAccordionTabs()', id);

	$('.js-tab-content').each(function (index, value) { 
		//console.log('conent:', index, value);
		var contentId = $(this).data('content');

		if(contentId == id) {
			//console.log('toggle:', contentId);
			if($(this).hasClass('active')) {
				if(breakpointMobile > $(window).width()) {
					$(this).removeClass('active');
					$('.js-tab-button[data-tab=' + id + ']').removeClass('active');
				}
			} else {
				$(this).addClass('active');
				$('.js-tab-button[data-tab=' + id + ']').addClass('active');
			}
		} else {
			//console.log('close:', contentId);
			if($(this).hasClass('active')) $(this).removeClass('active');
			$('.js-tab-button[data-tab=' + contentId + ']').removeClass('active');
		}

	});

}


/**
 * Resize wrapper for height
 * @param {*} id 
 */
function resizeWrapper (id) {
	//console.log('resizeWrapper()', id);

	var $wrapper = $('.js-accordion-tabs'),
		$activeContent = $('.js-tab-content[data-content=' + id + ']');

	$wrapper.css({'height': 'auto'});

	var	wrapperHei = $wrapper.outerHeight(),
		contentHei = $activeContent.outerHeight();

	// console.log('wrapperHei:', wrapperHei);
	// console.log('contentHei:', contentHei);

	if (wrapperHei < contentHei) {
		//console.log('update :', contentHei);
		$wrapper.css({'height': contentHei + 'px'});
	} else {
		$activeContent.css({'height': '100%'});
	}

	wasMobile = isMobile;

	if(breakpointMobile > $(window).width()) {
		isMobile = true;
	} else {
		isMobile = false;
	}
	// console.log('wasMobile:', wasMobile);
	// console.log('isMobile:', isMobile);

	if(wasMobile && !isMobile) {
		//console.log('switched from mobile to dtop');
		toggleAccordionTabs(activeTabId);
	}

	
}



$(document).ready(function() {
	//console.log('accordion-tabs');
	if($('.js-accordion-tabs').width() > 5) {
		//console.log('FOUND accordion-tabs!!');
		initAccordionTabs();
	}
});