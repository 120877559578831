//--------------------------------------------------------------------//
//  SCROLL PAGE BACK TO TOP
//--------------------------------------------------------------------//
$('.js-scroll-top').click(function() {
    $("html, body").animate({scrollTop: 0}, 1000);
});

//--------------------------------------------------------------------//
//  FORM LABEL FOCUS
//--------------------------------------------------------------------//
$('.form-control').focus(function() { 
    var thisControl = $(this);
    thisControl.closest('.form-group').addClass('active');
});

$('.form-control').blur(function() { 
    var thisControl = $(this);
    if (thisControl.val()) {
        thisControl.closest('.form-group').addClass('has-value');
        thisControl.closest('.form-group').removeClass('active');
    } else {
        thisControl.closest('.form-group').removeClass('has-value');
        thisControl.closest('.form-group').removeClass('active');
    }
});

//--------------------------------------------------------------------//
// function stickyNavTop() {
//     var navOffsetTop = $('.js-main-nav').offset().top,
//         offsetString = -navOffsetTop + "px";
//     $('.js-page-header').css("top", offsetString);
// }
//----------------------------------------------------------------//



//  Check to see if nav should be stuck
function stickyNav() {
    var $window = $(window);
    var header = $('.js-header');
    var headerHeight = header.outerHeight();
    var stuckElements = $('.stuck');
    // position: sticky polyfill    
    Stickyfill.add(stuckElements);
    // On scroll, check to see if header nav should be compressed or expanded
    $window.scroll(function (event) {
        var scrollTop = $($window).scrollTop();
        
        if (scrollTop >= headerHeight - 20) {
            $('header').addClass('compressed');
        } else if (scrollTop < 40) {
            $('header').removeClass('compressed');
        }

        if ($('.js-desktop-search').hasClass('open')) {
            $('.js-desktop-search').toggleClass('open');
        }
        
    });
}

//--------------------------------------------------------------------//
//  SWITCH BACKGROUND IMAGES ON DUAL IMAGE BACKGROUND BLOCKS
//--------------------------------------------------------------------//
function switchBgImage() {
    $('.js-bg-dual-image').each(function() {
        var image = $(this);
        var switchImgWidth = Modernizr.mq('(min-width: 768px)');
        var imageUrlLarge = image.attr('data-bg-image-lg');
        var imageUrlSmall = image.attr('data-bg-image-sm');
            // console.log(imageUrl);
        if (switchImgWidth) {
            image.css('background-image', 'url(' + imageUrlLarge + ')');
        }
        else {
            image.css('background-image', 'url(' + imageUrlSmall + ')');
        }
    });
}

//--------------------------------------------------------------------//
//  PAGE LOAD
//--------------------------------------------------------------------//
(function (window) {
    // console.log('load');
    
    //----------------------------------------------------------------//
    //  Check form fields for existing values on page load
    //----------------------------------------------------------------//
    $('.form-control').each(function() {
        // console.log('form-control');
        
        var thisControl = $(this);
        if (thisControl.val()) {
            console.log('has values');
            
            thisControl.closest('.form-group').addClass('has-value');
    //         // thisControl.closest('.form-group').removeClass('active');
    //     } 
    //     // else {
    //     //     thisControl.closest('.form-group').removeClass('has-value')
    //     //     thisControl.closest('.form-group').removeClass('active');
        }
    });

    //--------------------------------------------------------------------//
    //  MOBILE NAV TOGGLING
    //--------------------------------------------------------------------//
    $('.js-nav-toggler').click(function() {
        var navToggler = $(this);
        navToggler.toggleClass('open');
    });
    
    //----------------------------------------------------------------//
    //  DESKTOP GLOBAL SEARCH
    //----------------------------------------------------------------//
    $('.js-search-trigger').click(function(e){
        e.preventDefault();
        $('.js-desktop-search').toggleClass('open');
        setTimeout(function(){
            //console.log('focussss search');
            $('#SearchBoxDesktop').focus();
        },250);
    });

    $('.js-searchbox-desktop').keyup(function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            if ($('.js-searchbox-desktop').val().length >= 3) {
                __doPostBack('p$lt$ctl01$SearchBox$SearchLinkButton', '');
            }
        }
    });
    // 
    $(document).keyup(function(e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            if ($('.js-desktop-search').hasClass('open')) {
                $('.js-desktop-search').removeClass('open');
            }
        }
    });

    //----------------------------------------------------------------//
    //  MOBILE NAV TOGGLING
    //----------------------------------------------------------------//
    $('.js-nav-toggler').click(function(){
        $('body').toggleClass('nav-open');
    });

    //----------------------------------------------------------------//
    //  APPLY BG IMAGE CSS USING DATA ATTRIBUTE
    //  
    //  Usage: 
    //  <div data-bg-image="path/to/image"></div>
    //----------------------------------------------------------------//
    $('.js-bg-image').each(function() {
        var image = $(this);
        var imageUrl = image.attr('data-bg-image');
            // console.log(imageUrl);
        image.css('background-image', 'url(' + imageUrl + ')');
    });
    $(window).smartresize(function() {
    });

    //----------------------------------------------------------------//
    //  MAKE ENTIRE ELEMENT CLICKABLE
    //  - finds a nested <a> and navigates to it 
    //----------------------------------------------------------------//
    $(".js-clickable").click(function () {
        thisLink = $(this).find("a");
        if ($(this).find("a").attr("target") === "_blank") {
            // console.log('external link: ' + thisLink.attr("href"));
            window.open(thisLink.attr("href"), '_blank'); 
            return false;
        } else {
            // console.log('internal link: ' + thisLink.attr("href"));
            window.location = thisLink.attr("href");
            return false;
        }
    });
    // Reset video src to stop modal videos when modal is closed
    $('[id*="VideoModal"]').on('hidden.bs.modal', function () {
        var video = $(this).closest('.modal').find('iframe');
        var videoSrc = video.attr('src');
        // Update iframe src with its own source to destroy and rebuild video
        video.attr('src', videoSrc);
    })

    switchBgImage();
    stickyNav();
    
    //----------------------------------------------------------------//
    //  HERO SLIDER 
    //----------------------------------------------------------------//
    $('.js-hero-slider').slick({
        arrows: true,
        dots: true
    });

    //----------------------------------------------------------------//
    //  CONTENT BLOCK SLIDER
    //----------------------------------------------------------------//
    $('.js-content-slider').slick({
        arrows: true,
        dots: true
    })

    //----------------------------------------------------------------//
    //  DAIRY EXPORT SLIDER
    //----------------------------------------------------------------//
    $('.js-export-slider').slick({
        arrows: true,
        dots: false,
        prevArrow: $('#exportPrev'),
        nextArrow: $('#exportNext')
    })

    // $('.js-accordion-category').click(function() {
    //     var thisCategory = $(this);
    //     var categoryContent = thisCategory.find('.js-accordion-content');
        
    //     if (categoryContent.hasClass('show')) {
    //         categoryContent.collapse('hide')
    //     }	else {
    //         categoryContent.collapse('show')
    //     }
    // });

    $( ".js-accordion-category" ).each(function(index) {
        $(this).on("click", function(){
            var thisCategory = $(this);
            var categoryContent = thisCategory.next('.js-accordion-content');
            var icon = thisCategory.find('svg');
            
            if (categoryContent.hasClass('show')) {
                categoryContent.collapse('hide')
                icon.addClass('fa-folder').removeClass('fa-folder-open').attr('data-prefix', 'fas');
            }	else {
                categoryContent.collapse('show');
                icon.addClass('fa-folder-open').removeClass('fa-folder').attr('data-prefix', 'fal');
            }
        });
    });

    $(".js-expand-all").on('click', function(e){
        e.preventDefault();

        var allAccordionContent = $('.js-accordion-content');
        var allAccordionCategory = $('.js-accordion-category');

        if ($(".js-expand-all").html().indexOf('Collapse') !== -1) {
            allAccordionContent.collapse('hide');
            allAccordionCategory.find('svg').addClass('fa-folder').removeClass('fa-folder-open').attr('data-prefix', 'fas');
            $(".js-expand-all")
                .find('span')
                    .html('Expand All Folders');
            $(".js-expand-all")
                .find('svg')
                    .addClass('fa-folder')
                    .removeClass('fa-folder-open')
                    .attr('data-prefix', 'fas');
            
        } else {
            allAccordionContent.collapse('show');
            allAccordionCategory.find('svg').addClass('fa-folder-open').removeClass('fa-folder').attr('data-prefix', 'fal');
            $(".js-expand-all")
                .find('span')
                    .html('Collapse All Folders');
            $(".js-expand-all")
                .find('svg')
                    .addClass('fa-folder-open')
                    .removeClass('fa-folder')
                    .attr('data-prefix', 'fal');
        }
    })

    $('#contactFormButton').on("click", function() {
        $("#districtField").html($(this).data('district-number'));
        $("#nameField").html($(this).data('name'));
    });

    $(document).on('click', '.logon-password-retrieval-link', function () {
        setTimeout(function () {
            if ($('.js-password-reset-email').length > -1) {
                $('.js-password-reset-email').focus();
            }
        }, 800);
    });

}(window)); 

$(document).ready(function(){
    $('.js-dropdown-toggle-custom').on('click',function(){
        var $this = $(this);
        setTimeout(function(){
            $('.js-dropdown-toggle-custom').each(function(){
                var dropdownToggles = $(this);
                if(dropdownToggles.attr('aria-expanded') == "false"){
                    dropdownToggles.find('[data-fa-i2svg]').removeClass('fa-minus').addClass('fa-plus');
                }
            });
            
            if($this.attr('aria-expanded') == "true"){
                $this.find('[data-fa-i2svg]').removeClass('fa-plus').addClass('fa-minus');
            } else {
                $this.find('[data-fa-i2svg]').removeClass('fa-minus').addClass('fa-plus');
            }
        }, 80)
    })
})

$(window).smartresize(function() {
    switchBgImage();
});



