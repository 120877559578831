

function initializeChart () {
	//console.log('initializeChart');

	var chartData = $("#chartData").data("chartdata");
	console.log('chartData:', chartData);
	console.log('chartData:', JSON.stringify(chartData));

	var ctx = document.getElementById('exportChart').getContext('2d');
	var exportChart = new Chart(ctx, {
		type: 'bar',
		data: chartData,
		options: {
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						// Include a dollar sign in the ticks
						callback: function(value, index, values) {
							//return '$' + value;
							return (value).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD',
							});
						}
					}
				},
			},
			plugins: {
				tooltip: {
					callbacks: {
						label: function(context) {
							var label = context.dataset.label || '';
							if (label) {
								label += ': ';
							}
							if (context.parsed.y !== null) {
								label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
							}
							return label;
						}
					}
				}
			}
		}
	});
}

$(document).ready(function() {
	//console.log('export-graph');

	if($('#exportChart').width() > 5) {
		initializeChart();
	}
	
});


// {"labels":["January","February","March","April","May","June"],"datasets":[{"label":"My First dataset","backgroundColor":"rgb(255, 99, 132)","borderColor":"rgb(255, 99, 132)","data":[0,10,5,2,20,30,45]},{"label":"My Second dataset","backgroundColor":"rgb(99, 132, 222)","borderColor":"rgb(99, 132, 222)","data":[15,1,12,10,14,20,35]}, {"label":"My Third dataset","backgroundColor":"rgb(132, 222, 99)","borderColor":"rgb(132, 222, 99)","data":[14,20,35,15,1,12,10]}]}