function calculateTotalValue(length) {
    //length += 10;
    var minutes = Math.floor(length / 60),
        seconds_int = length - minutes * 60,
        seconds_str = Math.ceil(seconds_int).toString(),
        seconds = (seconds_str.length == 2) ? seconds_str : '0' + seconds_str,
        time = minutes + ':' + seconds;

    // console.group('calculateTotalValue()');
    // console.log('length: ', length);
    // console.log('minutes: ', minutes);
    // console.log('seconds_int: ', seconds_int);
    // console.log('seconds_str: ', seconds_str);
    // console.log('seconds: ', seconds);
    // console.log('time: ', time);
    // console.groupEnd();

    return time;
}


function calculateCurrentValue(currentTime) {
    var current_hour = parseInt(currentTime / 3600) % 24,
        current_minute = parseInt(currentTime / 60) % 60,
        current_seconds_long = currentTime % 60,
        current_seconds = current_seconds_long.toFixed(),
        current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

    return current_time;
}


function initPlayers() {
    //console.log('initPlayers()');

    $('.js-audio-row').each(function( index ) {
        //console.log('js-audio-row.index: ', index);

        var that = this;
        var waitForInt = setInterval(function () {
            //wait for metadata to exist before updating
            if ($(that).find('.js-player').get(0).duration > 10) {
                clearInterval(waitForInt);
                onTimeUpdate();
            }
        }, 10);

        var player = $(this).find('.js-player').get(0),
            playBtn = $(this).find('.js-play-btn'),
            startTime = $(this).find('.js-start-time'),
            endTime = $(this).find('.js-end-time'),
            progressbar = $(this).find('.js-seekObj');

        // console.log('player: ', player);

        // Controls Listeners
        // ----------------------------------------------------------
        if (playBtn != null) {
            playBtn.on('click', function () {
                togglePlay();
            })
        }

        player.ontimeupdate = function(){
            //console.log('player.currentTime:', player.currentTime);
            onTimeUpdate();
        };

        function onTimeUpdate () {
            //console.log('onTimeUpdate()');
            var length = player.duration,
                current_time = player.currentTime;

            // calculate total length of value
            var totalLength = calculateTotalValue(length);
            if (endTime.text() != totalLength) {
                endTime.html(totalLength);//don't need to keep reseting this
            }

            // calculate current value time
            var currentTime = calculateCurrentValue(current_time);
            startTime.html(currentTime);
            //console.log('totalLength: ', totalLength);

            var percPlayed = (player.currentTime / player.duration);
            //console.log('percPlayed: ', percPlayed);
            if (!isNaN(percPlayed)) {
                progressbar.get(0).value = percPlayed;
            }
            //console.log('progressbar: ', progressbar);

            progressbar.on('click', function (evt) {
                var percent = evt.offsetX / this.offsetWidth;
                player.currentTime = percent * player.duration;
                progressbar.get(0).value = percent / 100;
            });

            if (player.currentTime == player.duration) {
               playBtn.removeClass('pause');
            }
        }

        // Controls & Sounds Methods
        // ----------------------------------------------------------
        function togglePlay() {
            //console.log('togglePlay()');
            //console.log('player.paused: ', player.paused);
            if (player.paused === false) {
                player.pause();
                playBtn.removeClass('pause');

            } else {
                pauseOthers ();
                player.play();
                playBtn.addClass('pause');
            }
        }

        function pauseOthers () {
            //console.log('pauseOthers()');
            $('.js-player').each(function( index ) {
                var isPaused = $(this).get(0).paused;
                //console.log('isPaused: ', isPaused);
                if (!isPaused) {
                    $(this).get(0).pause();
                }
            });
            $('.js-play-btn').each(function( index ) {
                $(this).removeClass('pause');
            });
        }
    });

}


$(document).ready(function () {
    var audioRows = jQuery('.js-audio-row').length;
    //console.log('audioLength: ', audioRows);
    if (audioRows > 0) {
        initPlayers();
    }
});