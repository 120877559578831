(function() {
    var mediaQuery = window.matchMedia('(min-width: 992px)');
    var dashMenu = $('.js-menu-trigger');
    var dashMenuTrigger = $('.js-menu-trigger').find('a');
    var dashMenuCollapse = $('.js-menu-collapse');
    
    $('.js-menu-trigger').click(function() {
        // var dashMenuTrigger = $(this).find('a');
        // dashMenu.toggleClass('rounded-top');
        setTimeout(function() {
            if ($(dashMenuTrigger).attr('aria-expanded') === "true") {
                dashMenu.addClass('rounded-top');
            }
            else {
                dashMenu.removeClass('rounded-top');
            }
        },100)
    });

    if (mediaQuery.matches) {
        dashMenu.addClass('rounded-top');
        dashMenuCollapse.addClass('show');
    } else {
        $(dashMenu).removeClass('rounded-top');
        dashMenuCollapse.removeClass('show');
    }
    
    $(window).smartresize(function() {
        if (mediaQuery.matches) {
            dashMenu.addClass('rounded-top');
            dashMenuCollapse.addClass('show');
        } else {
            dashMenu.removeClass('rounded-top');
            dashMenuCollapse.removeClass('show');
        }
    });


})();