$(document).ready(function () {
    $(document).on("click", ".js-remove-save", function (e) {
        e.preventDefault();
        var element = this;
        var id = $(this).data('pageid');
        $.ajax({
            type: "POST",
            url: "/CMSPages/WebService.asmx/RemoveSave",
            data: { "pageId": id },
            success: function () {
                if ($(element).find("svg").attr("data-icon") == "trash") {
                    $(element).find("svg").attr("data-icon", "star");
                }

                if ($(element).find("p")[0].innerText == "UNSAVE") {
                    $(element).find("p")[0].innerText = "SAVE";
                    if ($(element).find("svg").attr("data-icon") == "save") {
                        console.log('Save');
                        $(element).find("svg").attr("data-prefix", "far");
                    }
                    
                }

                $(element).removeClass("js-remove-save");
                $(element).addClass("js-add-save");

                $(element).closest(".js-save-item").remove();
            },
            error: function (e) {
                console.log(e);
            }
        })
    });

    $(document).on("click", ".js-add-save", function (e) {
        e.preventDefault();
        var id = $(this).data('pageid');
        var element = this;
        $.ajax({
            type: "POST",
            url: "/CMSPages/WebService.asmx/AddSave",
            data: { "pageId": id },
            success: function () {
                if ($(element).find("svg").attr("data-icon") == "star") {
                    $(element).find("svg").attr("data-icon", "trash");
                }

                if ($(element).find("p")[0].innerText == "SAVE") {
                    $(element).find("p")[0].innerText = "UNSAVE";
                    if ($(element).find("svg").attr("data-icon") == "save") {
                        console.log('Unsave');
                        $(element).find("svg").attr("data-prefix", "fas");
                    }
                }

                $(element).removeClass("js-add-save");
                $(element).addClass("js-remove-save");
            },
            error: function (e) {
                console.log(e);
            }
        })
    })
});