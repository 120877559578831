$(document).ready(function () {
    $(document).on("click", ".js-update-resource-views", function (e) {
        var nodeGuid = $(this).data('nodeguid');
        var nodeName = $(this).data('nodename');
        $.ajax({
            type: "POST",
            url: "/CMSPages/WebService.asmx/UpdateResourceView",
            data: { "nodeGuid": nodeGuid, "nodeName": nodeName },
            success: function () {
                
            },
            error: function (e) {
                console.log(e);
            } 
        })
    });
    $(".js-resource-collapse").click(function() {
        var trigger = $(this).find('.js-resource-collapse-trigger');
        trigger.toggleClass('d-none')
    })
});