
var initMaterials = function () {

	/**
	 * Define Materials vars
	*/
	var storageCart = sessionStorage.getItem("materialsCart"),
		materialsCart = storageCart ? JSON.parse(storageCart) : [];

	/**
	 * Define button actions
	*/
	// $(document).on('click', '.js-load-materials', function() {
	// 	//console.log('click js-load-materials');
	// 	$(this).removeClass('btn-link').addClass('btn-secondary');//codebehind is handling this now
	// 	clearCart();
	// });


	$(document).on('click', '.js-materials-addtocart', function() {
		var itemId = $(this).closest('.js-material').data('itemid'),
			itemName =$(this).closest('.js-material').data('itemname'),
			quantity = $(this).parent().parent().find('.js-material-quantity').val();


		var findItem = materialsCart.find(x => x.id == itemId);

		if(findItem) {
			//update existing item
			if(quantity > 0){
				findItem.quantity = quantity;
			} else {
				var findIndex = materialsCart.findIndex(x => x.id == itemId);
				materialsCart.splice(findIndex, 1);
			}

		} else {
			//add new item
			var inventoryNumber = $(this).closest('.js-material').data('inventorynumber');
			if(quantity > 0) {
				materialsCart.push({id: itemId, quantity: quantity, name: itemName, inventoryNumber: inventoryNumber});
			}
		}
		
		sessionStorage.setItem("materialsCart", JSON.stringify(materialsCart));
		updateCart();
	});


	//don't need this right now. codebehind should be handling the submit and the JSON data gets updated below and pushed into a hidden field
	// $(document).on('click', '.js-materials-request', function() {
	// 	//console.log('click js-materials-request');
	// 	alert('Request to Order: ' + JSON.stringify(materialsCart));
	// });

	/**
	 * Clear Cart and update
	*/
	var clearCart = function () {
		materialsCart = [];
		sessionStorage.removeItem("materialsCart");
		updateCart();
	}

	/**
	 * Update Cart HTML
	*/
	var updateCart = function() {
		$ulHtml = '';
		if(materialsCart.length > 0) {
			materialsCart.forEach(material => {
				$ulHtml += '<li>' + material.name + ' (' + material.quantity + ')</li>';
			});
			//$(".js-materials-request").attr("hidden", false);
			$(".js-materials-request").removeClass("hidden");
			$(".js-materials-clear").removeClass("hidden");
		} else {
			$ulHtml += '<li>Items in your order will appear here.</li>';
			$(".js-materials-request").addClass("hidden");
			$(".js-materials-clear").addClass("hidden");
		}
		$('.js-materials-cart ul').html($ulHtml);
		$('#hdnMaterialsCart').val(JSON.stringify(materialsCart));//update hidden field for codebehind

	};

	//run inital cart update
	updateCart();

	return {
		clearCart: clearCart,
	}

}();

// Category switch/empty cart confirm
var emptyCartConfirm = function () {
		var storageCart = sessionStorage.getItem("materialsCart")
		var confirm_value = document.createElement("INPUT");
		confirm_value.type = "hidden";
		confirm_value.name = "confirm_value";
		if (storageCart != null) {
			if (confirm("By changing your event type, your cart will be cleared. Are you sure you want to continue?")) {
				confirm_value.value = "Yes";
				initMaterials.clearCart();
				confirm_value.remove();
			} else {
				confirm_value.value = "No";
				confirm_value.remove();

			}
		}
		document.forms[0].appendChild(confirm_value);
	}


/**
 * Wait for DOM
*/
$(document).ready(function() {
	if($('.js-materials-cart').width() > 5) {
		initMaterials();
	}
});