$(document).ready(function () {
    $(document).on("click", ".js-remove-favorite", function (e) {
        e.preventDefault();
        var element = this;
        var guid = $(this).data('pageguid');
        if (confirm("Are you sure you want to remove this favorite?")) {
            $.ajax({
                type: "POST",
                url: "/CMSPages/WebService.asmx/RemoveFavorite",
                data: { "pageGuid": guid },
                success: function () {
                    if ($(element).find("svg").attr("data-icon") == "trash") {
                        $(element).find("svg").attr("data-icon", "star");
                    }

                    if ($(element).find("p")[0].innerText == "UNFAVORITE") {
                        $(element).find("p")[0].innerText = "FAVORITE"
                    }

                    $(element).removeClass("js-remove-favorite");
                    $(element).addClass("js-add-favorite");

                    $(element).closest(".js-favorite-item").remove();
                },
                error: function (e) {
                    console.log(e);
                }
            })
        }
    });

    $(document).on("click", ".js-add-favorite", function (e) {
        e.preventDefault();
        var guid = $(this).data('pageguid');
        var element = this;
        $.ajax({
            type: "POST",
            url: "/CMSPages/WebService.asmx/AddFavorite",
            data: { "pageGuid": guid },
            success: function () {
                if ($(element).find("svg").attr("data-icon") == "star") {
                    $(element).find("svg").attr("data-icon", "trash");
                }

                if ($(element).find("p")[0].innerText == "FAVORITE") {
                    $(element).find("p")[0].innerText = "UNFAVORITE"
                }

                $(element).removeClass("js-add-favorite");
                $(element).addClass("js-remove-favorite");
            },
            error: function (e) {
                console.log(e);
            }
        })
    })
});